import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "theme-ui";
import { IconCross } from "../icons";

export default function ProjectSection({ children, value, id, onChange }) {
	const isOpen = value === id;
	return (
		<Box
			sx={{
				position: "relative",
				maxHeight: isOpen ? "9999px" : 0,
				height: [isOpen ? "auto" : 0, "auto"],
				opacity: isOpen ? 1 : 0,
				visibility: isOpen ? "visible" : "hidden",
				transition: "all 400ms ease",
			}}
		>
			<IconButton
				onClick={onChange}
				aria-label="Close button"
				data-testid="close-button"
				sx={{
					position: "absolute",
					fontSize: "18px",
					top: ["-15px", "10px"],
					right: ["-15px", "10px"],
					color: "accent",
				}}
			>
				<IconCross />
			</IconButton>
			<Box
				sx={{
					bg: "highlight",
					pt: [4, 6],
					pb: 4,
					px: 4,
					mb: 5,
				}}
			>
				{children}
			</Box>
		</Box>
	);
}

ProjectSection.defaultProps = {
	value: null,
	onChange() {},
};

ProjectSection.propTypes = {
	children: PropTypes.node.isRequired,
	onChange: PropTypes.func,
	value: PropTypes.string,
	id: PropTypes.string.isRequired,
};
