import * as React from "react";

function DiagArrow(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#diag-arrow_svg__clip0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.044.53h13.792c.847 0 1.533.687 1.533 1.533v13.792a1.532 1.532 0 01-3.065 0V5.763l-14.24 14.24-2.168-2.167 14.24-14.24H4.045a1.532 1.532 0 110-3.065z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
}

export default DiagArrow;
