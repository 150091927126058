import React from "react";
import { Grid, Link, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function Jasper() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x4057/f1f7100f36/jasper-home.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x5132/2340b10d10/offers.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x2008/534396415d/notification.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Jasper</Heading>{" "}
					<Text variant="bodyDefault">Intermediate Front-End Developer</Text>
					<Text variant="bodySmall">Mar 2019 - Nov 2020 (1 yr 9 mos)</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Text
						variant="bodyDefault"
						as="ul"
						sx={{ paddingInlineStart: "20px" }}
					>
						<li>
							Worked as part of a two person front-end team to build the main{" "}
							<Link
								target="_blank"
								variant="default"
								href="https://app.jasper.io/create-account"
							>
								product app
							</Link>{" "}
							and the internal-only admin app (both using React with GraphQL).
						</li>
						<li>
							The product app allows users to create an account, complete
							onboarding, browse open offers, invest in properties and manage
							their portfolio. The admin app is used by Jasper employees to
							manage user accounts and the full offer-to-investment process.
						</li>
						<li>
							Built the initial version of (and continued development of) the
							jasper.io marketing site using Gatsby.
						</li>
						<li>
							Developed the internal component library, JUX, which is used
							across all three Jasper apps. This was built in close
							collaboration with the Jasper design team.
						</li>
					</Text>
				</ProjectDetailsBlock>
			</Grid>
			<Grid columns={[1, 3]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Key technologies
					</Heading>
					<Text variant="bodyDefault">
						React, Apollo, Cypress, Jest, GraphQL, X-State, Contentful, Styled
						System, Gatsby, Node, Github Actions, Sentry, Intercom, Linear
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						About Jasper
					</Heading>
					<Text variant="bodyDefault">
						Jasper acquires commercial property that investors can buy and sell
						shares in, through the Jasper app.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://app.jasper.io" mb={2}>
						Visit Jasper app
					</ExternalLink>
					<ExternalLink href="https://jasper.io">
						Visit marketing site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
