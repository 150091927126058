import React from "react";
import PropTypes from "prop-types";
import { Box } from "theme-ui";

function ProjectDetailsBlock({ children }) {
	return (
		<Box
			sx={{ borderTop: "1px solid", borderTopColor: "accent", py: 3, mb: 3 }}
		>
			{children}
		</Box>
	);
}

ProjectDetailsBlock.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ProjectDetailsBlock;
