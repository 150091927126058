import React from "react";
import { Grid, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function Curtains() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2876x1386/00469236f3/first.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2868x1376/1d28b115a3/second.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2876x2620/47011e48c8/third.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Curtains</Heading>{" "}
					<Text variant="bodyDefault">Personal project</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Text variant="bodyDefault">
						Curtains is a side project I have been working on for visualizing
						readymade curtain sizes on ecommerce websites. Different curtain
						sizes can be passed in the URL and the visualizer is embedded in an
						iframe or popup window. The user can then enter their room&#39;s
						dimensions and see how the selected sizes will fit exactly in their
						space, with warnings if the sizes are wrong.
					</Text>
				</ProjectDetailsBlock>
			</Grid>

			<Grid columns={[1, "2fr 1fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						How it&#39;s built
					</Heading>
					<Text variant="bodyDefault">
						The website is built using Next.js. Styling is done with Vanilla
						Extract and Sprinkles.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://curtains-snowy.vercel.app/?variants%5B0%5D%5Bw%5D=138&variants%5B0%5D%5Bh%5D=355&variants%5B0%5D%5Bid%5D=abc&variants%5B1%5D%5Bw%5D=95&variants%5B1%5D%5Bh%5D=260&variants%5B1%5D%5Bid%5D=def&unitLabel=cm&clientId=curtainstudio&title=Habitat%20Sundown%20Pencil%20Pleat%20Curtains%20Pair">
						Visit prototype site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
