import React from "react";
import { Box, Grid, Heading, Text } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function SalonStuttgart() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x1926/02ff6125de/home.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x4340/57824bf4a4/show.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x1786/62e1571149/contact.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Salon Stuttgart</Heading>{" "}
					<Text variant="bodyDefault">Short term contract</Text>
				</ProjectDetailsBlock>

				<ProjectDetailsBlock>
					<Text variant="bodyDefault">
						I was contracted to build the website for the gallery Salon
						Stuttgart in Berlin.
					</Text>
				</ProjectDetailsBlock>
			</Grid>
			<Grid columns={[1, 3]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Key technologies
					</Heading>
					<Text variant="bodyDefault">Nuxt (Vue), Storyblok</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						About the client
					</Heading>
					<Text variant="bodyDefault">
						Salon Stuttgart is a contemporary art project space located within a
						residential apartment in Berlin.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://salonstuttgart.com" mb={2}>
						Visit site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
