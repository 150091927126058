import React from "react";
import { Grid, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function BenClement() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x1702/fb09692703/index.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x3836/b303e82cc0/exhibition.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x4480/ca3822c960/expanded.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Ben R Clement</Heading>{" "}
					<Text variant="bodyDefault">Short term contract</Text>
				</ProjectDetailsBlock>

				<ProjectDetailsBlock>
					<Text variant="bodyDefault">
						I was contracted to build the portfolio website of Ben R Clement,
						using Gatsby and Contentful.
					</Text>
				</ProjectDetailsBlock>
			</Grid>

			<Grid columns={[1, 3]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Key technologies
					</Heading>
					<Text variant="bodyDefault">
						Gatsby (React), Theme UI , Storyblok
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						About the client
					</Heading>
					<Text variant="bodyDefault">
						Ben R Clement is a contemporary artist from Auckland, New Zealand,
						currently living and working in New York.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://ben-r-clement.com" mb={2}>
						Visit site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
