import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./assets/styles/theme";
import "./assets/styles/base.css";
import Switch from "./pages/Switch";
import "typeface-inconsolata";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Switch />
		</ThemeProvider>
	);
}

export default App;
