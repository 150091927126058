import React from "react";
import { Grid, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function StepLadder() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x1666/823303c5f2/initial-table.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x2080/02b0dc0361/paste-link.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x2348/d1b752b5a5/pulling-data.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Stepladder</Heading>{" "}
					<Text variant="bodyDefault">Personal project</Text>
					<Text variant="bodySmall">In progress</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Text variant="bodyDefault">
						Step ladder is a personal project I’ve been working on to help
						manage the process of buying a house. When you paste in a TradeMe
						real estate listing URL, it will scrape the data and display it in a
						consistent table for comparison.
					</Text>
				</ProjectDetailsBlock>
			</Grid>

			<Grid columns={[1, "2fr 1fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						How it&#39;s built
					</Heading>
					<Text variant="bodyDefault">
						The front-end app is built using React. This makes requests to an
						independent Node app that uses Puppeteer to scrape a TradeMe listing
						and return the formatted response. The backend (when complete) will
						use a Hasura GraphQl backend.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://agitated-wozniak-b46b07.netlify.app">
						Visit site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
