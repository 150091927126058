import React from "react";
import { Grid, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";

export default function Spark() {
	return (
		<Box>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Spark</Heading>{" "}
					<Text variant="bodyDefault">Senior Front-End Developer</Text>
					<Text variant="bodySmall">Sep 2022 - Mar 2023 (6 mos contract)</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Text
						variant="bodyDefault"
						as="ul"
						sx={{ paddingInlineStart: "20px" }}
					>
						<li>
							Worked on the completion and launch of the new Spark online store
							in Next.js. Key tasks included: Next.js SSG, design system
							components, schema markup and SEO, native-app webview, and general
							react development work.
						</li>
						<li>
							Worked as part of a new squad for relaunching one of Spark’s key
							product offerings.
						</li>
						<li>
							Conducted pull request reviews and provided feedback as an
							approved reviewer.
						</li>
					</Text>
				</ProjectDetailsBlock>
			</Grid>
			<Grid columns={[1, 3]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Key technologies
					</Heading>
					<Text variant="bodyDefault">
						React (Next.js), Typescript, Cypress, Storybook, GraphQL (Relay),
						Styled Components
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						About Spark
					</Heading>
					<Text variant="bodyDefault">
						Spark is the largest telecommunications company in New Zealand,
						providing a wide range of services including mobile, IoT, landline,
						and internet solutions.
					</Text>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
