import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Input } from "theme-ui";

const PinInput = forwardRef(
	({ value, onChange, focusNextInput, focusPreviousInput, ...rest }, ref) => {
		const handleChange = (e) => {
			onChange(e);
			if (e.target.value.length === 1) {
				setTimeout(focusNextInput, 0);
			}
		};

		const handleKeyDown = (e) => {
			if (value.length === 0 && e.keyCode === 8) {
				setTimeout(focusPreviousInput, 0);
			}
		};

		return (
			<Input
				sx={{
					width: "50px",
					height: "50px",
					fontSize: 8,
					textAlign: "center",
					borderRadius: 8,
					bg: "highlight",
					border: "1px solid",
					borderColor: "muted",
					fontFamily: "heading",
					transition:
						"box-shadow 400ms ease,border 200ms ease,transform 200ms ease",
					":focus": {
						boxShadow: "cardSmall",
						borderColor: "accent",
						outline: "none",
						transform: "scale(1.15)",
					},
				}}
				ref={ref}
				type="tel"
				maxLength={1}
				value={value}
				onKeyDown={handleKeyDown}
				onChange={handleChange}
				{...rest}
			/>
		);
	},
);

PinInput.defaultProps = {
	value: "",
	onChange() {},
	focusNextInput() {},
	focusPreviousInput() {},
};

PinInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	focusNextInput: PropTypes.func,
	focusPreviousInput: PropTypes.func,
};

export default PinInput;
