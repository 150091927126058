import React from "react";
import { Grid, Link, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function Adjust() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2548x3881/b797442326/homepage-crop.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2548x5125/e6aa5659aa/benchmarks-crop.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2548x4328/410adf9600/solutions-partners.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Adjust</Heading>{" "}
					<Text variant="bodyDefault">Front-End Developer</Text>
					<Text variant="bodySmall">Jul 2016 - Dec 2018 (2 yrs 6 mos)</Text>
				</ProjectDetailsBlock>

				<ProjectDetailsBlock>
					<Text
						variant="bodyDefault"
						as="ul"
						sx={{ paddingInlineStart: "20px" }}
					>
						<li>
							Developed the company website from a static Jekyll site to a
							headless CMS with Nuxt (Vue) front-end.
						</li>
						<li>
							Designed and built several other websites for the company,
							including a Docs site (Craft CMS), two conference websites (Vue)
							and a benchmarking dashboard (Vue).
						</li>
						<li>
							I led the design and development of the{" "}
							<Link
								target="_blank"
								variant="default"
								href="https://app-benchmarks.adjust.com/"
							>
								Adjust Global Benchmarks dashboard
							</Link>
							, an aggregated data microsite. The tool was used to generate
							leads for the company and was featured in{" "}
							<Link
								target="_blank"
								variant="default"
								href="https://venturebeat.com/2018/06/26/adjust-launches-open-source-performance-benchmarking-for-app-publishers/"
							>
								VentureBeat
							</Link>
							.
						</li>
					</Text>
				</ProjectDetailsBlock>
			</Grid>
			<Grid columns={[1, 3]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Key technologies
					</Heading>
					<Text variant="bodyDefault">
						Vue, Nuxt, Jekyll, Craft CMS, ChartsJS, Bulma
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						About Adjust
					</Heading>
					<Text variant="bodyDefault">
						Adjust is one of the world&#39;s largest mobile advertising
						attribution platforms.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://www.adjust.com/" mb={2}>
						Visit company site
					</ExternalLink>
					<ExternalLink href="https://app-benchmarks.adjust.com/">
						Visit benchmarks site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
