export const duration = 300;

export const gateDefaultStyle = {
	transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease`,
	opacity: 0,
	transform: "translateY(-50vh)",
	overflow: "hidden",
};

export const gateTransitionStyles = {
	entering: {
		opacity: 1,
		transform: "translateY(0)",
		visibility: "visible",
	},
	entered: {
		opacity: 1,
		transform: "translateY(0)",
		visibility: "visible",
	},
	exiting: {
		transform: "translateY(-100vh)",
		opacity: 0,
		visibility: "visible",
	},
};

export const letterDefaultStyle = {
	transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease`,
	transitionDelay: `${duration}ms`,
	opacity: 0,
	transform: "scale(0.8)",
	overflow: "hidden",
};

export const letterTransitionStyles = {
	entering: {
		opacity: 1,
		transform: "scale(1)",
	},
	entered: {
		opacity: 1,
		transform: "scale(1)",
	},
};
