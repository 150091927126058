import React from "react";
import { Grid, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";

export default function Onit() {
	return (
		<Box>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Onit</Heading>{" "}
					<Text variant="bodyDefault">Senior Front-End Developer</Text>
					<Text variant="bodySmall">Mar 2021 - May 2022 (1 yr 3 mos)</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Text
						variant="bodyDefault"
						as="ul"
						sx={{ paddingInlineStart: "20px" }}
					>
						<li>
							Worked across three customer facing React applications. I lead the
							front-end development work on two of these.
						</li>
						<li>
							Built and maintained an in-house component library which was used
							by three internal apps and one external team.
						</li>
						<li>
							Implemented Cypress automated testing across the front-end
							applications.
						</li>
						<li>
							Ran the development team Scrum meetings (planning, retro,
							refinement, stand-ups) on a temporary basis.
						</li>
					</Text>
				</ProjectDetailsBlock>
			</Grid>
			<Grid columns={[1, 3]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Key technologies
					</Heading>
					<Text variant="bodyDefault">
						React, Typescript, Cypress, Redux, Styled System, Storybook, Rollup,
						Styled Components
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						About Onit
					</Heading>
					<Text variant="bodyDefault">
						Onit specialises in software for enterprise legal teams. The New
						Zealand office is focused on AI applications in the contract review
						process.
					</Text>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
