import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "theme-ui";
import { IconDiagArrow } from "../icons";

function ExternalLink({ href, children, ...rest }) {
	return (
		<Button
			as="a"
			variant="mediumGhost"
			{...rest}
			sx={{
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
			href={href}
			target="_blank"
		>
			{children}
			<Box
				sx={{
					display: "inline",
					fontSize: "8px",
					color: "inherit",
					lineHeight: 0,
					ml: 2,
				}}
			>
				<IconDiagArrow />
			</Box>
		</Button>
	);
}

ExternalLink.propTypes = {
	children: PropTypes.node.isRequired,
	href: PropTypes.string.isRequired,
};

export default ExternalLink;
