import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, Heading, Text, Flex } from "theme-ui";
import PinInput from "../../components/PinInput";

function Gate({ onSuccess }) {
	const [firstInput, setFirstInput] = useState("");
	const [secondInput, setSecondInput] = useState("");
	const [thirdInput, setThirdInput] = useState("");
	const [fourthInput, setFourthInput] = useState("");

	const firstInputRef = useRef();
	const secondInputRef = useRef();
	const thirdInputRef = useRef();
	const fourthInputRef = useRef();

	useEffect(() => {
		const combinedInputs = `${firstInput}${secondInput}${thirdInput}${fourthInput}`;
		if (combinedInputs.length !== 4) {
			return;
		}
		if (combinedInputs === process.env.REACT_APP_KEY) {
			onSuccess();
		} else {
			setFirstInput("");
			setSecondInput("");
			setThirdInput("");
			setFourthInput("");
			firstInputRef.current.focus();
		}
	}, [firstInput, secondInput, thirdInput, fourthInput, onSuccess]);

	useLayoutEffect(() => {
		setTimeout(firstInputRef.current.focus, 0);
	}, []);

	return (
		<Flex
			sx={{
				px: 3,
				py: 5,
				alignItems: "center",
				justifyContent: ["start", "center"],
				flexDirection: "column",
				height: "100vh",
			}}
		>
			<Heading variant="headingSmall" as="h2" sx={{ mb: 2, mt: [0, "-100px"] }}>
				Please enter the pin
			</Heading>
			<Text variant="bodyDefault" as="p" sx={{ mb: 5 }}>
				Or{" "}
				<Link variant="default" href="mailto:georgefeast@gmail.com">
					email me
				</Link>{" "}
				for access
			</Text>
			<Flex
				sx={{
					alignItems: "center",
					justifyContent: "space-between",
					width: [250, 280],
				}}
			>
				<PinInput
					value={firstInput}
					autoFocus
					onChange={(e) => setFirstInput(e.target.value)}
					ref={firstInputRef}
					focusNextInput={() => secondInputRef.current.focus()}
					data-testid="first-input"
				/>
				<PinInput
					value={secondInput}
					onChange={(e) => setSecondInput(e.target.value)}
					ref={secondInputRef}
					focusNextInput={() => thirdInputRef.current.focus()}
					focusPreviousInput={() => firstInputRef.current.focus()}
					data-testid="second-input"
				/>
				<PinInput
					value={thirdInput}
					onChange={(e) => setThirdInput(e.target.value)}
					ref={thirdInputRef}
					focusNextInput={() => fourthInputRef.current.focus()}
					focusPreviousInput={() => secondInputRef.current.focus()}
					data-testid="third-input"
				/>
				<PinInput
					value={fourthInput}
					onChange={(e) => setFourthInput(e.target.value)}
					ref={fourthInputRef}
					focusPreviousInput={() => thirdInputRef.current.focus()}
					data-testid="fourth-input"
				/>
			</Flex>
		</Flex>
	);
}

Gate.propTypes = {
	onSuccess: PropTypes.func.isRequired,
};

export default Gate;
