import React, { Fragment, useState } from "react";
import { getFixedGatsbyImage } from "gatsby-storyblok-image";
import PropTypes from "prop-types";
import { Box, Card, Button, Flex, IconButton, Image } from "theme-ui";
import { IconCross } from "../icons";
import Modal from "./Modal";

function MockBrowser() {
	return (
		<Flex
			sx={{
				width: "100%",
				height: "30px",
				bg: "background",
				alignItems: "center",
				justifyContent: "start",
			}}
		>
			<Box
				sx={{
					width: "10px",
					height: "10px",
					bg: "#FC615D",
					borderRadius: "100%",
					ml: 2,
					mr: 2,
				}}
			/>
			<Box
				sx={{
					width: "10px",
					height: "10px",
					bg: "#FDBE41",
					borderRadius: "100%",
					mr: 2,
				}}
			/>
			<Box
				sx={{
					width: "10px",
					height: "10px",
					bg: "#34C749",
					borderRadius: "100%",
					mr: 2,
				}}
			/>
		</Flex>
	);
}

function ImageModal({ imageSrc }) {
	const [isOpen, setIsOpen] = useState(false);

	const thumbnailProps = getFixedGatsbyImage(imageSrc, {
		toFormat: "webp",
		width: 275,
		height: 176,
		quality: 0.7,
	});
	const largeProps = getFixedGatsbyImage(imageSrc, {
		toFormat: "webp",
		width: 945,
		quality: 0.7,
	});

	return (
		<Fragment>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<Box
					sx={{
						width: "90vw",
						maxWidth: 960,
						maxHeight: ["70vh", "80vh", "85vh"],
						overflow: "auto",
					}}
				>
					<IconButton
						onClick={() => setIsOpen(false)}
						sx={{
							bg: "highlight",
							p: 2,
							borderRadius: "100%",
							position: "absolute",
							fontSize: "20px",
							top: "-15px",
							right: "-15px",
							color: "accent",
							boxShadow: "elementSmall",
						}}
					>
						<IconCross />
					</IconButton>
					<Flex
						sx={{
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "space-between",
							overflow: "auto",
							height: "100%",
							borderRadius: "8px",
						}}
					>
						<Box>
							<MockBrowser />
							<Image {...largeProps} />
						</Box>
					</Flex>
				</Box>
			</Modal>
			<Button variant="link" onClick={() => setIsOpen(true)}>
				<Card variant="small" sx={{ display: "inline-block" }}>
					<Image {...thumbnailProps} />
				</Card>
			</Button>
		</Fragment>
	);
}

ImageModal.propTypes = {
	imageSrc: PropTypes.string.isRequired,
};

export default ImageModal;
