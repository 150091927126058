import React from "react";
import PropTypes from "prop-types";
import { Button } from "theme-ui";

function ProjectToggle({ children, value, id, onChange }) {
	const isOpen = id === value;
	return (
		<Button
			onClick={() => onChange(id)}
			variant="link"
			sx={{
				position: "relative",
				borderRadius: "none",
				outline: "none",
				color: isOpen ? "highlight" : "primary",
				"::before": {
					content: '""',
					zIndex: -2,
					position: "absolute",
					width: "calc(100% + 6px)",
					left: "-3px",
					height: "100%",
					bg: "transparent",
					bottom: 0,
					transition: "background-color 200ms ease, height 100ms ease",
				},
				"&:focus-visible": {
					"::before": {
						content: '""',
						bg: "highlight",
					},
				},
				"::after": {
					content: '""',
					zIndex: -1,
					position: "absolute",
					width: "calc(100% + 6px)",
					left: "-3px",
					height: isOpen ? "100%" : "2px",
					transition: "background-color 200ms ease, height 100ms ease",
					bg: "accent",
					bottom: 0,
				},
			}}
		>
			{children}
		</Button>
	);
}

ProjectToggle.defaultProps = {
	value: null,
	onChange() {},
};

ProjectToggle.propTypes = {
	children: PropTypes.node.isRequired,
	onChange: PropTypes.func,
	value: PropTypes.string,
	id: PropTypes.string.isRequired,
};
export default ProjectToggle;
