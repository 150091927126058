import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "theme-ui";
import ProjectToggle from "../../components/ProjectToggle";
import Stepladder from "./projects/Stepladder";
import Onit from "./projects/Onit";
import Jasper from "./projects/Jasper";
import Adjust from "./projects/Adjust";
import BenRClement from "./projects/BenRClement";
import SalonStuttgart from "./projects/SalonStuttgart";
import Spark from "./projects/Spark";
import Curtains from "./projects/Curtains";
import Ute from "./projects/Ute";
import useAccordionState from "../../components/utilities/useAccordionState";
import ProjectSection from "../../components/ProjectSection/ProjectSection";

const SECTION_ID_ONIT = "onit";
const SECTION_ID_SPARK = "spark";
const SECTION_ID_JASPER = "jasper";
const SECTION_ID_ADJUST = "adjust";
const SECTION_ID_STEPLADDER = "stepladder";
const SECTION_ID_UTE = "ute";
const SECTION_ID_BEN = "ben";
const SECTION_ID_SALON_STUTTGART = "stuttgart";
const SECTION_ID_CURTAINS = "curtains";

function LetterBlock({ children }) {
	return (
		<Text
			as="p"
			sx={{
				mx: "auto",
				fontSize: "large",
				maxWidth: 620,
				lineHeight: "expanded",
				color: "primary",
			}}
			mb={[3, 5]}
		>
			{children}
		</Text>
	);
}

LetterBlock.propTypes = {
	children: PropTypes.node.isRequired,
};

export default function Letter() {
	const accordionState = useAccordionState();
	return (
		<Box
			sx={{
				mx: "auto",
				p: [4, 5, 7],
			}}
		>
			<LetterBlock>Hi, I’m George.</LetterBlock>
			<LetterBlock>
				My most recent role was as a Senior Front-End Developer at{" "}
				<ProjectToggle id={SECTION_ID_SPARK} {...accordionState}>
					Spark
				</ProjectToggle>
				.
			</LetterBlock>
			<ProjectSection id={SECTION_ID_SPARK} {...accordionState}>
				<Spark />
			</ProjectSection>
			<LetterBlock>
				Before that, I worked as a Senior Front-End Developer at{" "}
				<ProjectToggle id={SECTION_ID_ONIT} {...accordionState}>
					Onit
				</ProjectToggle>
				.
			</LetterBlock>
			<ProjectSection id={SECTION_ID_ONIT} {...accordionState}>
				<Onit />
			</ProjectSection>
			<LetterBlock>
				Previously, I worked as an Intermediate Front-End Developer at{" "}
				<ProjectToggle id={SECTION_ID_JASPER} {...accordionState}>
					Jasper
				</ProjectToggle>
				.
			</LetterBlock>
			<ProjectSection id={SECTION_ID_JASPER} {...accordionState}>
				<Jasper />
			</ProjectSection>
			<LetterBlock>
				Prior to that, I worked as a Front-End Developer at{" "}
				<ProjectToggle id={SECTION_ID_ADJUST} {...accordionState}>
					Adjust
				</ProjectToggle>{" "}
				in Berlin.
			</LetterBlock>
			<ProjectSection id={SECTION_ID_ADJUST} {...accordionState}>
				<Adjust />
			</ProjectSection>
			<LetterBlock>
				I also like to work on side projects, like{" "}
				<ProjectToggle id={SECTION_ID_CURTAINS} {...accordionState}>
					Curtains
				</ProjectToggle>
				,{" "}
				<ProjectToggle id={SECTION_ID_UTE} {...accordionState}>
					Ute
				</ProjectToggle>{" "}
				and{" "}
				<ProjectToggle id={SECTION_ID_STEPLADDER} {...accordionState}>
					Stepladder
				</ProjectToggle>
				.
			</LetterBlock>
			<ProjectSection id={SECTION_ID_CURTAINS} {...accordionState}>
				<Curtains />
			</ProjectSection>
			<ProjectSection id={SECTION_ID_UTE} {...accordionState}>
				<Ute />
			</ProjectSection>
			<ProjectSection id={SECTION_ID_STEPLADDER} {...accordionState}>
				<Stepladder />
			</ProjectSection>
			<LetterBlock>
				Occasionally, I do contract work for clients such as the artist{" "}
				<ProjectToggle id={SECTION_ID_BEN} {...accordionState}>
					Ben R Clement
				</ProjectToggle>{" "}
				and the gallery{" "}
				<ProjectToggle id={SECTION_ID_SALON_STUTTGART} {...accordionState}>
					Salon Stuttgart
				</ProjectToggle>
				.
			</LetterBlock>
			<ProjectSection id={SECTION_ID_BEN} {...accordionState}>
				<BenRClement />
			</ProjectSection>
			<ProjectSection id={SECTION_ID_SALON_STUTTGART} {...accordionState}>
				<SalonStuttgart />
			</ProjectSection>
			<LetterBlock>
				Before I was a developer, I completed a Bachelor of Commerce, (majoring
				in Political Science and Marketing). I also worked in several design and
				marketing roles.
			</LetterBlock>
			<LetterBlock>
				Cheers,
				<br /> George
			</LetterBlock>
		</Box>
	);
}
