/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "theme-ui";
import { createPortal } from "react-dom";
import useLockBodyScroll from "./useBodyLockScroll";

const wrapperStyle = {
	position: "fixed",
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 1000,
};

const maskStyle = {
	position: "fixed",
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	backgroundColor: "rgba(10, 48, 69, 0.5)",
	zIndex: 100000,
};

const containerStyleFor = {
	modal: {
		position: "relative",
		zIndex: 100001,
	},
	drawer: {
		position: "absolute",
		zIndex: 100002,
		right: 0,
		top: 0,
		bottom: 0,
	},
};

function ModalContainer({ children, onClose, ...rest }) {
	useLockBodyScroll();
	useEffect(() => {
		function keyListener(e) {
			if (e.keyCode === 27) {
				onClose();
			}
		}

		document.addEventListener("keydown", keyListener);

		return () => document.removeEventListener("keydown", keyListener);
	});
	return <Box {...rest}>{children}</Box>;
}

ModalContainer.defaultProps = {
	children: null,
	onClose() {},
};

ModalContainer.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func,
};

function Modal({ children, isOpen, variant, onClose, elementId }) {
	if (isOpen === false) {
		return null;
	}

	const containerStyle = containerStyleFor[variant];

	return createPortal(
		<Box sx={wrapperStyle}>
			<Box sx={maskStyle} onClick={onClose} tabIndex={0} />
			<ModalContainer onClose={onClose} sx={containerStyle}>
				{children}
			</ModalContainer>
		</Box>,
		document.getElementById(elementId),
	);
}

Modal.defaultProps = {
	variant: "modal",
	elementId: "root",
};
Modal.propTypes = {
	children: PropTypes.node.isRequired,
	isOpen: PropTypes.bool.isRequired,
	variant: PropTypes.oneOf(["modal", "drawer"]),
	onClose: PropTypes.func.isRequired,
	elementId: PropTypes.string,
};

export default Modal;
