import React, { Fragment, useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Transition } from "react-transition-group";
import { Box } from "theme-ui";
import Letter from "../Letter/Letter";
import Gate from "../Gate";
import {
	duration,
	gateDefaultStyle,
	gateTransitionStyles,
	letterDefaultStyle,
	letterTransitionStyles,
} from "./transitions";

export default function Switch() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			scroll.scrollToTop({ duration: "200ms" });
		}
	}, [isAuthenticated]);

	return (
		<Fragment>
			<Transition in={!isAuthenticated} timeout={duration} appear unmountOnExit>
				{(state) => (
					<Box
						sx={{
							...gateDefaultStyle,
							...gateTransitionStyles[state],
						}}
					>
						<Gate onSuccess={() => setIsAuthenticated(true)} />
					</Box>
				)}
			</Transition>
			<Transition in={isAuthenticated} timeout={duration} appear unmountOnExit>
				{(state) => (
					<Box
						sx={{
							...letterDefaultStyle,
							...letterTransitionStyles[state],
						}}
					>
						<Letter />
					</Box>
				)}
			</Transition>
		</Fragment>
	);
}
