import React from "react";
import { Grid, Link, Heading, Text, Box } from "theme-ui";
import ProjectDetailsBlock from "../../../components/ProjectDetailsBlock/ProjectDetailsBlock";
import { ImageModal } from "../../../components/Modal";
import ExternalLink from "../../../components/ExternalLink";

export default function Ute() {
	return (
		<Box>
			<Grid columns={[1, 3]} variant="default" mb={5}>
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x1464/2de6aea243/dairy-home.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x1392/08d2a3023d/cart-drawer.png" />
				<ImageModal imageSrc="https://a.storyblok.com/f/52938/2560x2544/cfb7e0033b/confirmation.png" />
			</Grid>
			<Grid columns={[1, "1fr 2fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingMedium">Ute</Heading>{" "}
					<Text variant="bodyDefault">Personal project</Text>
					<Text variant="bodySmall">Lockdown 2020</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Text variant="bodyDefault">
						Ute is a personal project I worked on during lockdown. I wanted to
						make a way for dairies and small businesses to have the same
						&#39;click and collect&#39; technology the big supermarkets were
						using. This project was mainly an exploration of using X-State
						(state machine library) and a UI experimentation.
					</Text>
				</ProjectDetailsBlock>
			</Grid>

			<Grid columns={[1, "2fr 1fr"]} variant="default">
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						How it&#39;s built
					</Heading>
					<Text variant="bodyDefault">
						The front-end app is built using React with Theme UI . App state and
						the order flow are managed using{" "}
						<Link
							target="_blank"
							variant="default"
							href="https://xstate.js.org/docs/"
						>
							X-State
						</Link>
						.
					</Text>
				</ProjectDetailsBlock>
				<ProjectDetailsBlock>
					<Heading variant="headingSmall" sx={{ mb: 2 }}>
						Links
					</Heading>
					<ExternalLink href="https://laughing-raman-a4d301.netlify.app/">
						Visit site
					</ExternalLink>
				</ProjectDetailsBlock>
			</Grid>
		</Box>
	);
}
