import * as React from "react";

function Cross(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.5 9.571l-6.571 6.571-1.414-1.414 6.57-6.571L1 2.07 2.414.657 8.5 6.743 14.657.586 16.07 2 9.914 8.157l6.642 6.642-1.414 1.414L8.5 9.571z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default Cross;
