import { useState } from "react";

const useAccordionState = (initialId = null) => {
	const [value, setValue] = useState(initialId);
	const handleChange = (newValue) => {
		setValue((previousValue) => {
			if (newValue === previousValue) {
				return null;
			}
			return newValue;
		});
	};
	return { value, onChange: handleChange };
};

export default useAccordionState;
